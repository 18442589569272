@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-moz-keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-o-keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@keyframes progress {
  from {
    transform: scaleX(0);
    transform-origin: left;
  }
  to {
    transform-origin: left;
    transform: scaleX(1);
  }
}
